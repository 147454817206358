import React, { useLayoutEffect, useState, Fragment } from 'react';
import {graphql, Link, navigate} from 'gatsby';
import { Redirect } from '@reach/router';
import qs from 'querystring';
import Spin from '../../components/Spin';
import { getUpsells } from '../../services/pages.svc';
import cart from '../../utils/cart';
import SEO from "../../components/SEO";
import "../../styles/_all.scss";

export const query = graphql`
    query offerPageQuery {
        settings: sanitySiteSettings {
            title
            id
            facebook
            instagram
            twitter
            _rawOpenGraph(resolveReferences: { maxDepth: 10 })
            _rawLogo(resolveReferences: { maxDepth: 10 })
        }
    }
`;

export default ({ location, data: settingsData }) => {
  const [loading, setLoading] = useState(true);
  const [upsells, setUpsells] = useState([]);
  const { i } = qs.parse(location.search.substr(1));

  useLayoutEffect(() => {
    if (!upsells.length) {
      getUpsells().then((r) => {
        setUpsells(r);
        setLoading(false);
      });
    }
  }, [i]);

  if (i === undefined) {
    return <Redirect to="/404" />;
  }

  const nextIdx = Number(i) + 1;
  const data = upsells[i] || {};
  const nextData = upsells[nextIdx];
  let nextLink = '/offer/confirm';
  if (nextData && nextData.enabled === 1 && nextData.key !== 'break') {
    nextLink = `/offer?i=${nextIdx}`;
  }

  const onAccept = () => {
    const offers = cart.get('offers') || [];
    offers.push(data);
    cart.set('offers', offers);
    navigate(nextLink);
  };

  return (
    <Fragment>
      <SEO
        title="Start Trial"
        siteName={settingsData.settings.title}
        description={settingsData.settings.description}
      />
      {loading ? (
        <Spin spinning />
      ) : (
        <div className="section-spacer-bottom shape-background shape-background-dots shape-background-left">
          <div className="row text-center">
            <div className="col-xs-16 col-xs-offset-1 col-md-10 col-md-offset-4">
              <h1 className="text-blue">{data.offer_h1}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-16 col-xs-offset-1 col-md-10 col-md-offset-4">
              <div dangerouslySetInnerHTML={{ __html: data.offer_video }} />
              <h3 dangerouslySetInnerHTML={{ __html: data.offer_h2 }} />
              <p className="text-center">
                <button onClick={onAccept} className="button button-blue">
                  Yes, I want this
                </button>
              </p>
              <div dangerouslySetInnerHTML={{ __html: data.offer_details }} />
              <p className="text-center">
                <button
                  onClick={onAccept}
                  type="submit"
                  className="button button-blue button-cta button--loading"
                >
                  <span className="button-text">Yes, I want this</span>
                </button>
                <span style={{ marginLeft: '1rem' }}>
                  <Link to={`${nextLink}`}>No thanks, I'll pass</Link>
                </span>
              </p>
            </div>
          </div>
          <div className="row text-center">
            <div
              className="col text-center"
              dangerouslySetInnerHTML={{ __html: data.offer_disclaimer }}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};
